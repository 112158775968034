"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";

export const QueryProviders = ({ children }) => {
	// const queryClient = new QueryClient({
	// 	defaultOptions: {
	// 		queries: {
	// 			refetchOnWindowFocus: false,
	// 		},
	// 	},
	// });
	const [queryClient] = useState(() => new QueryClient());
	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};
