"use client";
import { createContext, useState, useMemo } from "react";
const Context = createContext({});

export const ContextProvider = ({ children }) => {
	const [generateCaptcha, setGenerateCaptcha] = useState(true);
	const [captchaResult, setCaptchaResult] = useState("");
	const [captcha, setCaptcha] = useState(null);
	const [txnId, setTxnId] = useState("");
	const [abhaDetails, setAbhaDetails] = useState(null);

	const [openToast, setOpenToast] = useState(false);
	const [message, setMessage] = useState("");

	const dependencies = [
		generateCaptcha,
		setGenerateCaptcha,
		captchaResult,
		setCaptchaResult,
		captcha,
		setCaptcha,
		txnId,
		setTxnId,
		abhaDetails,
		setAbhaDetails,
		setOpenToast,
		openToast,
		setMessage,
		message,
	];
	const contextValue = useMemo(
		() => ({
			generateCaptcha,
			setGenerateCaptcha,
			captchaResult,
			setCaptchaResult,
			captcha,
			setCaptcha,
			txnId,
			setTxnId,
			abhaDetails,
			setAbhaDetails,
			setOpenToast,
			openToast,
			setMessage,
			message,
		}),
		dependencies
	);
	return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default Context;
