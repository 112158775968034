"use client";
import { ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(5, 60, 97, 1)",
    },
    secondary: {
      main: "rgba(5, 60, 97, 1)",
    },
  },
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         backgroundColor: "rgba(5, 60, 97, 1)",
  //         root: {
  //           "&:disabled": {
  //             // Add your custom styles for the disabled state here
  //             // For example:
  //             backgroundColor: "#E7E7E7",
  //             color: "#B4B4B4",
  //           },
  //         },
  //       },
  //     },
  //   },
});

const ThemeContext = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContext;
