"use client";
import { createContext, useContext, useState } from "react";
import Context from "./context";

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
	const [message, setMessage] = useState("");
	const [openToast, setOpenToast] = useState(false);

	return (
		<ToastContext.Provider
			value={{
				message,
				setMessage,
				openToast,
				setOpenToast,
				// handleCloseToast,
			}}
		>
			{children}
		</ToastContext.Provider>
	);
};

export default ToastContext;
